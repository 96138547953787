import { pushToDatalayer } from "../utils/tracking/gtmTracking";


export const trackerClickTrack = ({ label = "" }) => {
    try{
        const payload = {
            'category': 'TSR',
            'event': 'TSR_Track Request',
            'action': 'TSR_Track Request',
            'label': label ? label : 'TSR_Track Request',
        }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalayer')
    }
}

export const trackerViewAllUpdates = () => {
    try{
        const payload = {
            'category': 'TSR',
            'event': 'TSR_View all updates',
            'action': 'TSR_View all updates',
            'label': 'TSR_View all updates',
        }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalayer')
    }
}

export const trackerCollapseAllUpdates = () => {
    try{
        const payload = {
            'category': 'TSR',
            'event': 'TSR_Collapse all updates',
            'action': 'TSR_Collapse all updates',
            'label': 'TSR_Collapse all updates',
        }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalayer')
    }
}

export const trackerUploadEstimateSubmit = () => {
    try{
        const payload = {
            'category': 'TSR',
            'event': 'TSR_ Upload Estimate_Submit',
            'action': 'TSR_ Upload Estimate_Submit',
            'label': 'TSR_ Upload Estimate_Submit',
        }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalayer')
    }
}

export const trackerUploadInvoiceSubmit = () => {
    try{
        const payload = {
            'category': 'TSR',
            'event': 'TSR_ Upload Invoice_Submit',
            'action': 'TSR_ Upload Invoice_Submit',
            'label': 'TSR_ Upload Invoice_Submit',
        }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalayer')
    }
}

export const trackerUploadBankDetailsSubmit = () => {
    try{
        const payload = {
            'category': 'TSR',
            'event': 'TSR_ Upload Bank details_Submit',
            'action': 'TSR_ Upload Bank details_Submit',
            'label': 'TSR_ Upload Bank details_Submit',
        }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalayer')
    }
}

export const trackerPayInvoiceAmountClick = () => {
    try{
        const payload = {
            'category': 'TSR',
            'event': 'TSR_ Pay Invoice Amount_Submit',
            'action': 'TSR_ Pay Invoice Amount_Submit',
            'label': 'TSR_ Pay Invoice Amount_Submit',
        }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalayer')
    }
}
