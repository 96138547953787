import { PushToTracker  } from "../../utils/tracking";

// data contains - { link, page }
export const trackRequestButtonPressed = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Track Request Button Pressed', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { link, page }
export const trackRequestListViewed = ( data ) => {
    //  confirmation needed - list view vs detail view
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Track Request List View', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}


// data contains - { link, page }
export const cancelServiceRequestClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Cancel Service Request Clicked', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { link, page }
export const cancellationSuccess = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Cancellation Success', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { link, page }
export const trackerActionClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Tracker Action Clicked', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { link, page }
export const trackerActionSuccess = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Tracker Action Success', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}
