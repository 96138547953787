import * as trackerActionTypes from '../actionTypes/trackerActionTypes';
import { GetSRTrackingDetails, SearchSRs } from '../../api/selfserve/tracker';
import { trackRequestButtonPressed} from '../../tracking/segment/trackerPage';
import { getMethodName } from '../../utils';
import { trackServiceRequestScreenViewed } from '../../tracking/segment/header';
import { pageCallSegment } from '../../utils/tracking/segmentTracking';
import { getPageViewData } from '../../utils/tracking';

export const searchServiceRequests = (searchValue:any, page:number = 1) => {
    return dispatch => {
        dispatch({
            type: trackerActionTypes.BEGIN_SEARCH,
        });
        SearchSRs(searchValue, page).then((data) => {
            let method = getMethodName(searchValue);
            const sr_data = data?.data;
            if(sr_data) {
                dispatch({
                    type: trackerActionTypes.SEARCH_SERVICE_REQUESTS,
                    value: searchValue,
                    results: sr_data.results,
                    count: sr_data.count,
                    next: sr_data.next,
                    previous: sr_data.previous,
                    valid: sr_data.valid,
                    page,
                });
            }
            if(data?.data.count!=0){
                trackRequestButtonPressed({'method':method, 'value':searchValue, "status":"success"}) 
            }else{
                trackRequestButtonPressed({'method':method, 'value':searchValue, "status":"failed"}) 
            }
        })
    }
}

export const loadSRDetails = (ref_no:any) => {
    return dispatch => {
        dispatch({
            type: trackerActionTypes.BEGIN_LOAD_SR_DETAIL,
        });
        GetSRTrackingDetails(ref_no).then((data) => {
            const srDetail = data?.data;
            pageCallSegment("Track Service Request Screen Viewed")
            trackServiceRequestScreenViewed({'ref_no':srDetail.ref_no, 'source':'website','current_status':srDetail.current_status, ...getPageViewData()})
            dispatch({
                type: trackerActionTypes.LOAD_SERVICE_REQUEST_DETAIL,
                data: srDetail || {},
            });
        })
        .catch((err)=>{
            console.log(err)
            dispatch({
                type: trackerActionTypes.LOAD_SERVICE_REQUEST_DETAIL,
                data: {},
            });
        })
    }
}

export const setAddressDetails = (data:any) => {
    return dispatch => {
        dispatch({
            type: trackerActionTypes.SR_ADDRESS_DETAILS,
            data: data
        })
    }
}

export const setLoader = (data:any) => {
    return dispatch => {
        dispatch({
            type: trackerActionTypes.BEGIN_LOADER,
            data: data
        })
    }
}

export const setCurrentStep = (data:any) => {
    return dispatch => {
        dispatch({
            type: trackerActionTypes.CURRENT_STEP,
            data: data
        })
    }
}

export const resetAddress = (data:any) => {
    return dispatch => {
        dispatch({
            type: trackerActionTypes.RESET_ADDRESS,
            data: data
        })
    }
}

export const setAPILatLng = (data:any) => {
    return dispatch => {
        dispatch({
            type: trackerActionTypes.API_LAT_LNG,
            data: data
        })
    }
}

export const setAddressComponent = (data:any) => {
    return dispatch => {
        dispatch({
            type: trackerActionTypes.ADDRESS_COMPONENT,
            data: data
        })
    }
}

export const setAddressErrors = (data:any) => {
    return dispatch => {
        dispatch({
            type: trackerActionTypes.ERRORS,
            data: data
        })
    }
}

export const setTempLatLng = (data:any) => {
    return dispatch => {
        dispatch({
            type: trackerActionTypes.TEMP_LAT_LNG,
            data: data
        })
    }
}

export const setCurrLatLng = (data:any) => {
    return dispatch => {
        dispatch({
            type: trackerActionTypes.CURR_LAT_LNG,
            data: data
        })
    }
}


export const setMapFullScreen = (data:any) => {
    return dispatch => {
        dispatch({
            type: trackerActionTypes.MAP_FULL_SCREEN,
            data: data
        })
    }
}

export const setCTSource = (data:any) =>{
    return dispatch => {
        dispatch({
            type: trackerActionTypes.CT_SOURCE,
            data: data
        })
    }
}