import Config from '../../../config';
import { GetCall, PostCallMultiForm, PatchCall, PostCall } from '../../helper';


const BASE_API_URL = Config.BASE_API_URL
const DEFAULT_HEADER = Config.DEFAULT_HEADER

const URLS = {
    "TRACK_SR": BASE_API_URL + "/v3/tracker",
    "SEARCH": BASE_API_URL + "/v3/tracker/search",
    "FETCH_BANK_DETAILS": BASE_API_URL + "/v3/tracker/fetch_bank_details",
    "UPLOAD_BANK_DETAILS": BASE_API_URL + "/v3/tracker/actions/upload_bank_details",
    "UPLOAD_SELFREPAIR_DOCUMENT": BASE_API_URL + "/v3/tracker/actions/upload_selfrepair_document",
    "UPLOAD_ATHOME_INVOICE": BASE_API_URL + "/v3/tracker/actions/upload_athome_document",
    "CANCEL_SR": BASE_API_URL + "/v3/tracker/actions/cancel_sr",
    "SR_ADDRESS": BASE_API_URL + "/v3/tracker/address_confirmation_detail",
    "CONFIRM_ADDRESS_DETAIL": BASE_API_URL + "/v3/tracker/confirm_address_detail"
}



export const GetSRTrackingDetails = async (ref_no:string) => {
    return await GetCall(`${URLS.TRACK_SR}/${ref_no}`,{'application': 'website' }, DEFAULT_HEADER)
}

export const FetchBankDetails = async (ref_no:string, token_id:string) => {
    return await GetCall(`${URLS.FETCH_BANK_DETAILS}/${ref_no}`,{'application': 'website', token_id}, DEFAULT_HEADER)
}

export const SearchSRs = async (searchValue:string, page:number) => {
    return await GetCall(`${URLS.SEARCH}`,{'application': 'website', 'search_value': searchValue, page }, DEFAULT_HEADER)
}

export const UploadBankDetails = async(ref_no:string, data:any) => {
    return await PostCallMultiForm(`${URLS.UPLOAD_BANK_DETAILS}/${ref_no}`, data, {});
}

export const UploadSelfRepairDocuments = async(ref_no:string, data:any) => {
    return await PostCallMultiForm(`${URLS.UPLOAD_SELFREPAIR_DOCUMENT}/${ref_no}`, data, {});
}

export const UploadAtHomeInvoice = async(ref_no:string, data:any) => {
    return await PostCallMultiForm(`${URLS.UPLOAD_ATHOME_INVOICE}/${ref_no}`, data, {});
}

export const CancelSR = async (ref_no:string, data:any) => {
    return await PatchCall(`${URLS.CANCEL_SR}/${ref_no}`, {application: "website", data}, DEFAULT_HEADER)
}

export const GetSRAddressDetail = async (ref_no:string) => {
    return await GetCall(`${URLS.SR_ADDRESS}/${ref_no}`,{'application': 'website'}, DEFAULT_HEADER)
}

export const ConfirmAddressDetail = async (data:any) => {
    return await PostCall(`${URLS.CONFIRM_ADDRESS_DETAIL}`, {application: "website", data}, DEFAULT_HEADER)
}